<template>
  <div id="app">
    <my-header @childvalue="childvalue"></my-header>
    <div class="page-mh">
        <keep-alive v-if="$route.meta.cache">
          <router-view :cur='curindex'></router-view>
        </keep-alive>
        <router-view :cur='curindex' v-else></router-view>
    </div>
    
    <my-footer></my-footer>
    <fixed-msg></fixed-msg>
  </div>
  
</template>

<script>
import myHeader from '@/components/header';
import myFooter from '@/components/footer';
import fixedMsg from '@/components/leavemsg'
export default {
  name: 'App',
  data(){
    return{
      curindex:''
    }
  },
  components:{
    myHeader,
    myFooter,
    fixedMsg
  },
  mounted(){
    this.childvalue()
  },
  methods:{
   childvalue(val){
      this.curindex = val
    }

  }
}
</script>
